<template>

<div id="admission_bg">
    <div id="form_container" class="clearfix">
		
		<img src="../../assets/flogo.png" width="200" height="200" style="margin-top: -50px" alt="">
		
		<div id="wizard_container">
			<!-- /top-wizard -->
            <Loading v-if="Loading" /> 
            <Errors v-if="errors!=''">
                <ul>
                    <li>{{errors}}</li>
                </ul>
            </Errors>
			<form name="example-1" id="wrapped" method="POST" v-if="Success">
				<input id="website" name="website" type="text" value="">
				<!-- Leave for security protection, read docs for details -->
				<div id="middle-wizard">
					<div class="step">
						<div id="intro">
							<figure><img src="img/wizard_intro_icon.svg" alt=""></figure>
							<h1>Request Granted</h1>
							<p>Your request to change your password has been granted and password has been changed</p>
                            <router-link :to="'/login'">
                                <div style="color: #EF6421; margin-top: 10px">Proceed to <strong><a>Login</a></strong></div>
                            </router-link>
						</div>
					</div>
				</div>
			</form>
		</div>
		<!-- /Wizard container -->
	</div>
</div>
    
</template>

<script>
    import Loading from '@/components/Loading.vue'
    //import Success from '@/components/Success.vue'
	import Errors from '@/components/Errors.vue'
	import { mapActions, mapGetters } from 'vuex';
    export default {
		name: 'Login',
		data(){
			return{
				userId: this.$route.params.userId,
				token: this.$route.params.token,
				Loading: true,
                errors: '',
                Success: false,
			}
		},
		computed: {
			...mapGetters(['error','publicToken'])
		},
		components: {
			Errors,
            Loading,
            //Success
        },
		methods:{
			...mapActions(['reset_error','changePassword'])
		},
        mounted(){
            this.errors = ''
            this.reset_error()
            let object = {
                userId: this.userId, 
				token: this.token,
				publicToken: this.publicToken,
            }
            this.changePassword(object)
            .then(res => {
                if (res.data.success) {
                    this.Success = true
                }else{
                    this.errors = res.data.message
                }
                this.Loading = false
            })
		}
    }
</script>

<style>
	h4{
		display: none;
	}
	.back{
		width: 100%;
		height: 750px;
		background-color: red;
		background: url(../../assets/12.jpg) center center no-repeat fixed;
	}
</style>